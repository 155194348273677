<template>
  <CRow id="userRow">
    <CCol col="12" id="userCol">
      <CCard class="shadow-sm" id="userCard">
        <CCardBody id="userCardBody">
          <CJumbotron style="padding: 2%;padding-bottom: 0%;" class="col-md-12" color="white" id="userJumbotron">
            <div class="d-inline-flex align-items-start" id="userJumbotronHeader">
              <h2 class="text-left font-weight-normal" id="userJumbotronTitle">
                {{ $t('management.user') }}
              </h2>
              <HelpButton :isHeader="false" class="ml-2 mt-2" id="userHelpButton"></HelpButton>
            </div>
            <hr id="userJumbotronDivider" />

            <table style="width:100%" id="userDetailTable">
              <tr class="h5 text-left" id="userDetailRow1">
                <td class="font-weight-normal text-muted" id="userDetailMemberDetail">
                  {{ $t('memberDetail') }}
                </td>
                <td class="font-weight-normal" id="userDetailShopNameLabel">{{ $t('shopname') }}</td>
                <td class="text-right" id="userDetailShopName">{{ shopName }}</td>
              </tr>
              <br id="userDetailSpacer" />
              <tr class="h5 text-left" id="userDetailRow2">
                <td></td>
                <td class="font-weight-normal" id="userDetailEmailLabel">{{ $t('email') }}</td>
                <td class="text-right" id="userDetailEmail">{{ users.email }}</td>
              </tr>
            </table>
          </CJumbotron>
        </CCardBody>
      </CCard>
      <PackageAndHistory id="userPackageAndHistory"></PackageAndHistory>
      <CButton variant="outline" @click="dangerModal = true" block color="danger" class="px-4 mb-4"
        id="userSignOutButton">
        <span id="userSignOutIcon"><i class="fa fa-sign-out"></i></span>
        {{ $t('signOut') }}
      </CButton>
    </CCol>
    <CModal color="danger" :show.sync="dangerModal" centered :footer="footer" :title="$t('signOut')"
      id="userSignOutModal">
      <h4 class="text-center" id="userSignOutDialogTitle">{{ $t('dialogSignOut') }}</h4>
      <br id="userSignOutDialogSpacer" />
      <h5 class="text-center" id="userSignOutDialogConfirm">{{ $t('confirmSignOut') }}</h5>
      <template #footer>
        <CRow class="justify-content-center col-md-12" id="userSignOutFooterRow">
          <CCol col="5" lg="4" id="userSignOutFooterColSubmit">
            <CButton block v-if="loadingButton === true" color="danger" @click="logout()" id="userSignOutSubmitButton">
              {{ $t('submit') }}
            </CButton>
            <CButton block color="danger" disabled v-else-if="loadingButton === false"
              id="userSignOutSubmitDisabledButton">
              <CSpinner color="white" size="sm" id="userSignOutSpinner" /> {{ $t('submit') }}
            </CButton>
          </CCol>
          <CCol col="1" lg="2" id="userSignOutFooterSpacer"></CCol>
          <CCol col="5" lg="4" id="userSignOutFooterColCancel">
            <CButton block color="light" @click="dangerModal = false" id="userSignOutCancelButton">
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
      </template>
    </CModal>
  </CRow>
</template>

<script>
import firebase from 'firebase/app'
import { mapGetters } from 'vuex'
import moment from 'moment'
import pos from '@/services/local'
import PackageAndHistory from './PackageAndHistory.vue'
import HelpButton from '../../containers/HelpButton.vue'

export default {
  data() {
    return {
      dangerModal: false,
      footer: '',
      loadingButton: true,
    }
  },
  components: {
    PackageAndHistory,
    HelpButton
  },
  computed: {
    ...mapGetters(['users', 'permissions']),
    shopName() {
      if (this.users.shopName) {
        return this.users.shopName
      } else {
        return '-'
      }
    },
    currentPlan() {
      let user = this.users.currentPlan
      if (user) {
        let plan = '-'
        if (user == 'multiplePOS') {
          plan = 'SMART'
        } else if (user == 'starter') {
          plan = 'STARTER'
        } else if (user == 'standard') {
          plan = 'STANDARD'
        } else {
          plan = '-'
        }
        return plan
      } else {
        return '-'
      }
    },
    start() {
      if (this.users.planUpgradeAt) {
        return moment(String(this.users.planUpgradeAt)).format(
          'DD MMMM YYYY HH:mm:ss'
        )
      } else {
        return '-'
      }
    },
    expire() {
      if (this.users.expire) {
        return moment(String(this.users.expire)).format('DD MMMM YYYY HH:mm:ss')
      } else {
        return '-'
      }
    },
  },

  created() {
    // if (!localStorage.getItem("token")) {
    //   localStorage.setItem("fromPath", 'user')
    //   this.$router.push('/login')
    // } else {

    // }
  },
  methods: {
    logout() {
      this.loadingButton = false
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.clear()
          window.location.href = '/login'
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
