<template>
    <CRow id="packageAndHistoryRow">
        <CCol v-if="loading" col="12" id="packageAndHistoryCol">
            <CCard class="shadow-sm p-0" id="packageAndHistoryCard">
                <CCardBody v-if="shopPackage[0]" id="packageAndHistoryCardBody">
                    <div class="card tabs p-0" id="packageAndHistoryTabs">
                        <input type="radio" id="tab1" name="tab-control" checked />
                        <input type="radio" id="tab2" name="tab-control" />
                        <ul class="ulbar font-weight-normal text-dark" id="packageAndHistoryUlbar">
                            <li :title="$t('packgeUsageHistory')" id="packageAndHistoryUsageHistoryTab">
                                <label for="tab1" role="button">
                                    <em class="fa fa-user d-lg-none d-md-none"></em>
                                    <br /><span> {{ $t('packgeUsageHistory') }}</span>
                                </label>
                            </li>
                            <li :title="$t('purchaseHistory')" id="packageAndHistoryPurchaseHistoryTab">
                                <label for="tab2" role="button">
                                    <em class="fa fa-money d-lg-none d-md-none"></em>
                                    <br /><span> {{ $t('purchaseHistory') }}</span>
                                </label>
                            </li>
                        </ul>
                        <div class="slider" id="packageAndHistorySlider">
                            <div class="indicator" id="packageAndHistoryIndicator"></div>
                        </div>
                        <div class="content" id="packageAndHistoryContent">
                            <!-- Package Usage -->
                            <section class="text-dark" id="packageAndHistoryUsageSection">
                                <div class="row " id="packageAndHistoryUsageRow">
                                    <div v-for="(shop, index) in shopPackage" v-if="shop.price > 0"
                                        class="col-lg-12 col-md-12 col-sm-12 mb-4"
                                        :id="'packageAndHistoryShop-' + (index)">
                                        •
                                        <template v-for="(shopDatail, index) in shop.shops">
                                            <span class="h4" v-if="index == 0" id="packageAndHistoryBranchName">{{
                                                $t('branchName') }} </span>
                                            <span class="h4" v-if="index !== 0"> / </span>
                                            <span class="h4" id="packageAndHistoryShopName">{{ shopDatail.name }}</span>
                                        </template>
                                        <CCard class="current-pack-card mt-2"
                                            :id="'packageAndHistoryShopCard-' + (index)">
                                            <CCardBody :id="'packageAndHistoryShopCardBody-' + (index)">
                                                <CCardTitle :id="'packageAndHistoryShopCardTitle-' + (index)">
                                                    <div class="row">
                                                        <div class="col-md-8 col-sm-12 mt-2">
                                                            <div class="d-flex">
                                                                {{ $t('packageName') }} {{
                                                                    getPlan(shop.plan) }} <div class="row h5 ml-2 ">
                                                                    <!-- <CBadge class="ml-2 mt-1" color="light">{{
            shop.type }}</CBadge> -->
                                                                    <CBadge class="ml-2 mt-1" color="light"
                                                                        :id="'packageAndHistoryShopPackageNo-' + (index)">
                                                                        #{{
                                                                            shop.packageNo }}</CBadge>

                                                                    <CBadge class="ml-2 mt-1" color="light"
                                                                        :id="'packageAndHistoryShopPackagePeriod-' + (index)">
                                                                        <template v-if="shop.period < 365">
                                                                            {{ $t('monthly') }} ( {{
                                                                                shop.period }} {{ $t('days') }} )
                                                                        </template>
                                                                        <template v-else>
                                                                            {{ $t('yearly') }} ( {{
                                                                                shop.period }} {{ $t('days') }} )
                                                                        </template>
                                                                    </CBadge>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-4 h5 text-right">( {{ $t('billingPackageCycle')
                                                            }}
                                                            {{
            shop.billingCycle }} )
                                                        </div> -->
                                                        <div class="col-md-4 col-sm-12 text-right mt-2">
                                                            <CButton variant="outline" block
                                                                @click="renewPackage(shop.objectId)"
                                                                style="font-size: medium;" color="success"
                                                                :id="'packageAndHistoryRenewButton-' + (index)">{{
                                                                    $t('renewPackage') }}</CButton>
                                                        </div>

                                                    </div>

                                                </CCardTitle>
                                                <CCardText style="font-size: medium;">
                                                    <div class="row mt-1">
                                                        <!-- <div class="col-6 text-left">
                                                            {{ $t('startPackate') }}
                                                        </div>
                                                        <div class="col-6 text-right">
                                                            {{ formatDate(shop.start) }}
                                                        </div>
                                                    </div>
                                                    <div class="row mt-1">
                                                        <div class="col-6 text-left">
                                                            {{ $t('endPackate') }}
                                                        </div>
                                                        <div class="col-6 text-right">
                                                            {{ formatDate(shop.end) }}
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2">
                                                        <div class="col-6 text-left ">
                                                            {{ $t('pricePackage') }}
                                                        </div>
                                                        <div class="col-6 text-right text-success h4">
                                                            {{ shop.price }} {{ $t('baht') }}
                                                        </div> -->
                                                    </div>
                                                    <!-- <hr /> -->
                                                    <!-- <div class="row mt-3">
                                                        <div v-for="shopDatail in shop.shops"
                                                            class="col-12 text-left pl-4 h5">
                                                            • {{ shopDatail.name }}
                                                        </div>

                                                    </div> -->
                                                </CCardText>
                                                <!-- <CButton @click="renewPackage(shop.objectId)"
                                                    style="font-size: medium;width: 100%;" color="success">{{
            $t('renewPackage') }}</CButton> -->
                                            </CCardBody>
                                        </CCard>
                                    </div>
                                </div>
                            </section>
                            <!-- Purchase History -->
                            <section id="renewPackageSection">

                                <DataTable class="text-dark" :items="getItems()" :fields="getColumn()" hover border
                                    id="renewPackageDataTable">
                                    <template #getReceipt="{ item, index }">
                                        <td class="text-center text-dark" :id="'renewPackageReceiptCell-' + index">
                                            <CButton v-if="packageHistory[index].paidData" variant="outline" HEAD
                                                @click="handleGetReceipt(index)" color="primary" :disabled="clicked">
                                                <img src="/img/icon/print.png" style="width: 18px; height:18px"
                                                    :id="'renewPackageReceiptIcon-' + index" />
                                            </CButton>
                                        </td>
                                    </template>
                                </DataTable>
                                <div style="position: fixed; width: 100%;bottom:1000vh"
                                    id="renewPackageInvoiceContainer">
                                    <Invoice class="mb-4" v-if="selectedPackageHistory.length !== 0"
                                        v-bind:packageHistory="selectedPackageHistory" id="renewPackageInvoice">
                                    </Invoice>
                                </div>
                            </section>
                        </div>
                    </div>
                </CCardBody>
                <div v-else class="text-center">
                    <br>

                    <template v-if="$i18n.locale === 'th'">
                        <img src="/img/pay01.jpg" width="100%">
                        <div class="d-flex justify-content-center">
                            <a id="renewPackageThaiContactLink"
                                href="https://liff.line.me/1645278921-kWRPP32q/?accountId=silompos" class="line"><img
                                    src="\img\line-icon.png" style="width: 20%; "><span
                                    style="vertical-align: middle; font-weight: lighter; color: white;">ติดต่อพนักงาน</span></a>
                        </div>
                    </template>
                    <template v-if="$i18n.locale === 'en'">
                        <img src="/img/pay02.jpg" width="80%">
                        <div class="d-flex justify-content-center">
                            <a id="renewPackageEngContactLink"
                                href="https://liff.line.me/1645278921-kWRPP32q/?accountId=silompos" class="line"><img
                                    src="\img\line-icon.png" style="width: 20%; "><span
                                    style="vertical-align: middle; font-weight: lighter; color: white;">Contect
                                    Us</span></a>
                        </div>
                    </template>
                    <br>
                </div>
            </CCard>
        </CCol>
    </CRow>
</template>


<script>
import { mapGetters } from 'vuex'
import '@/util/tabs.css'
import pos from '@/services/local';
import DataTable from '@/containers/DataTable'
import Invoice from '../template/Receipt-A4.vue'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import util from '@/util/util'

export default {
    components: {
        DataTable,
        Invoice,

    },
    data() {
        return {
            shopPackage: [],
            packageHistory: [],
            loading: false,
            indexHistory: 0,
            selectedPackageHistory: [],
            clicked: false,
        }
    },
    computed: {
        ...mapGetters(['shopLineOA']),
        uid() {
            return `${localStorage.getItem('uid')}`
        },
        shopObjectId() {
            return `${localStorage.getItem('shopObjectId')}`
        },

    },
    created() {
        this.getPackage()
        this.getPackageHistory()
    },
    methods: {
        trackButtonClick(buttonLabel) {
            if (typeof window.gtag === 'function') {
                window.gtag('event', 'click', {
                    event_label: buttonLabel,
                });
            } else {
                console.error('gtag is not defined.');
            }
        },
        handleGetReceipt(index) {
            this.getReceipt(index)
        },
        getReceipt(index) {
            this.clicked = true
            this.selectedPackageHistory = this.packageHistory[index]

            setTimeout(() => {
                const invoice = this.$el.querySelector('#invoice')
                html2canvas(invoice).then((canvas) => {
                    const imgData = canvas.toDataURL('image/jpeg')
                    const pdf = new jsPDF('p', 'pt', 'a4')
                    const imgWidth = 595.28 // A4 width in pt
                    const pageHeight = 841.89 // A4 height in pt
                    const imgHeight = (canvas.height * imgWidth) / canvas.width
                    let heightLeft = imgHeight
                    let position = 0

                    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
                    heightLeft -= pageHeight

                    while (heightLeft > 0) {
                        position = heightLeft - imgHeight
                        pdf.addPage()
                        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
                        heightLeft -= pageHeight
                    }
                    // Generate the PDF as a Blob
                    const pdfBlob = pdf.output('blob')
                    const pdfUrl = URL.createObjectURL(pdfBlob)

                    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
                    if (isSafari) {
                        const link = document.createElement('a')
                        link.href = pdfUrl
                        link.setAttribute('download', 'receipt.pdf')
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                    } else {
                        window.open(pdfUrl)
                    }
                    this.clicked = false
                })
            }, 2000)
        },


        renewPackage(objectId) {
            this.trackButtonClick('click/package_renew')
            localStorage.setItem("packageObjectId", objectId)
            this.$router.push('/user/package/renew')
        },
        formatDate(isoDate) {
            if (!isoDate || isoDate.trim() === '') {
                return " - ";
            }
            const date = new Date(isoDate)
            const day = String(date.getDate()).padStart(2, '0')
            const month = String(date.getMonth() + 1).padStart(2, '0')
            const year = date.getFullYear()
            const hours = String(date.getHours()).padStart(2, '0')
            const minutes = String(date.getMinutes()).padStart(2, '0')
            const seconds = String(date.getSeconds()).padStart(2, '0')

            return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`
        },

        getItems() {
            let items = []
            let i = 1

            if (this.packageHistory) {
                this.packageHistory.forEach(element => {
                    const branch = element.userPackage.shops.map(shop => shop.name).join(' / ')
                    items.push({
                        id: i,
                        refNum: element.userPackage.packageNo || '',
                        name: this.getPlan(element.userPackage.plan) || '',
                        branch: branch || '',
                        billingCycle: element.billingCycle || '',
                        date: this.formatDate(element.updated_at) || '',
                        price: util.convertCurrency(element.userPackage.price) || '',
                        getReceipt: '',
                        _cellProps: { id: { scope: 'row' } },
                        _classes: 'text-dark'
                    })
                    i++
                });
            }

            return items
        },
        getColumn() {
            return [
                {
                    key: 'id',
                    label: '#',
                    _props: { scope: 'col' },
                    _classes: 'text-dark'
                },
                {
                    key: 'refNum',
                    label: this.$i18n.t('packageNo'),
                    _props: { scope: 'col' },
                    _classes: 'text-dark'
                },
                {
                    key: 'name',
                    label: this.$i18n.t('packageName'),
                    _props: { scope: 'col' },
                    _classes: 'text-dark'
                },
                {
                    key: 'branch',
                    label: this.$i18n.t('branchName'),
                    _props: { scope: 'col' },
                    _classes: 'text-dark'
                },
                {
                    key: 'billingCycle',
                    label: this.$i18n.t('billingPackageCycle'),
                    _props: { scope: 'col' },
                    _classes: 'text-dark'
                },
                {
                    key: 'date',
                    label: this.$i18n.t('packagePurchaseDate'),
                    _props: { scope: 'col' },
                    _classes: 'text-dark'
                },
                {
                    key: 'price',
                    label: this.$i18n.t('pricePackage'),
                    _props: { scope: 'col' },
                    _classes: 'text-right text-dark'
                },
                {
                    key: 'getReceipt',
                    label: this.$i18n.t('receiptRenew'),
                    _props: { scope: 'col' },
                    _classes: 'text-center text-dark'
                },
            ]
        },
        getPlan(plan) {
            switch (plan) {
                case 'multiplePOS':
                    return 'SMART+';
                case 'starter':
                    return 'STARTER';
                case 'starterPlus':
                    return 'STARTER PLUS';
                default:
                    return plan
            }
        }
        ,
        getPackage() {
            let params = {
                shopObjectId: this.shopObjectId,
                extraFilter : 'price',
                extraVal : 100,
                extraOperator : 'greater'
            }
            let uid = this.uid

            pos({
                method: 'get',
                url: '/api/v2.2/' + uid + '/UserPackage',
                params: params,
            }).then((res) => {
                this.loading = true
                this.shopPackage = res.data.data
                this.shopPackage.sort((a, b) => new Date(a.end) - new Date(b.end))
            })
        },
        getPackageHistory() {
            let params = {
                shopObjectId: this.shopObjectId
            }
            let uid = this.uid

            pos({
                method: 'get',
                url: '/api/v2.2/' + uid + '/UserPackageHistory',
                params: params,
            }).then((res) => {
                this.packageHistory = res.data.data
            })

        }

    }
}
</script>


<style scoped>
.tabs .slider {
    position: relative;
    width: 50%;
    transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
}

.current-pack-card {
    border: 1px solid #ebebeb;
    border-radius: 5px;
}

.line {
    background-color: #00b900;
    width: 20%;
    display: block;
    padding: 6px;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
}
</style>
